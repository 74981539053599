import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TextInputComponent from "../../atom/Inputs/TextInput";
import { MINIMUM_PASSWORD_CHARACTERS, EMAIL_REGEX, getText, PASSWORD_REGEX, PHONE_REGEX } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import PhoneNumberComponent from "../../atom/Inputs/PhoneNumberInput";
import { parsePhoneNumber, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const SignUpFormOne = forwardRef(({ onPassValue = () => {}, isFree = 0, course_id, selectedCurrency, courseType }, ref) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [errorPassword, setPasswordError] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [ConfirmPasswordError, setConfirmPasswordError] = useState(false);

    const [fName, setFName] = useState('');
    const [isFNameValid, setIsFNameValid] = useState(false);
    const [fNameError, setFNameError] = useState(false);

    const [lName, setLName] = useState('');
    const [isLNameValid, setIsLNameValid] = useState(false);
    const [lNameError, setLNameError] = useState(false);

    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [countryCode, setCountryCode] = useState("+1");

    const [userExists, setUserExists] = useState(false);

    const verifyEmailData = useSelector(state => state.auth.get("verifyEmailData"));

    useEffect(() => {
        console.log("verifyEmailData:", verifyEmailData); // Debug log
        if (verifyEmailData?.status && verifyEmailData?.status_code === 1) {
            setUserExists(true);
            setIsEmailValid(true);
            setEmailError(false);
            setEmailErrorMessage('');
        } else if (verifyEmailData?.status_code === 0) {
            setUserExists(false);
            setIsEmailValid(true);
            setEmailError(false);
        } else if (verifyEmailData) {
            setUserExists(false);
            setIsEmailValid(false);
            setEmailError(true);
            // setEmailErrorMessage(verifyEmailData?.message || "Please add a valid email");
        }
    }, [verifyEmailData]);

    const onChangeFirstName = (e) => {
        const text = getText(e);
        setFName(text);
        setFNameError(false);
        setIsFNameValid(text?.length > 0);
    };

    const onChangeLastName = (e) => {
        const text = getText(e);
        setLName(text);
        setLNameError(false);
        setIsLNameValid(text?.length > 0);
    };

    const onEmailChange = (e) => {
        const text = getText(e);
        const reg = EMAIL_REGEX;
        const isValid = text.trim().length > 3 && reg.test(text.trim());
        setEmail(text);
        setIsEmailValid(isValid);
        setEmailErrorMessage("Please add valid email");
        setEmailError(false);
        if (isValid) {
            onEmailVerify(text);
        }
        // Reset userExists and password fields if email changes
        if (userExists) {
            setUserExists(false);
            setPassword('');
            setConfirmPassword('');
            setIsPasswordValid(false);
            setIsConfirmPasswordValid(false);
        }
    };

    const onEmailVerify = _.debounce((email) => {
        dispatch(Actions.auth.validateEmail({ email }));
    }, 1500);

    const onConfirmPassword = (e) => {
        const text = getText(e);
        setConfirmPassword(text);
        setConfirmPasswordError(false);
        setIsConfirmPasswordValid(text === password);
    };

    const onPasswordChange = (e) => {
        const text = getText(e);
        const isValid = text.trim().length > MINIMUM_PASSWORD_CHARACTERS && PASSWORD_REGEX.test(text.trim());
        setPassword(text);
        setPasswordError(false);
        setIsPasswordValid(isValid);
        setIsConfirmPasswordValid(text === confirmPassword);
    };

    const onPhoneChange = (text) => {
        setPhone(text);
        const isValid = text?.length > 3 && isPossiblePhoneNumber(text) && isValidPhoneNumber(text);
        if (isValid) {
            const phoneData = parsePhoneNumber(text);
            setCountryCode(`+${_.get(phoneData, "countryCallingCode")}`);
        }
        setIsPhoneValid(isValid);
        setPhoneError(false);
    };

    useImperativeHandle(ref, () => ({
        onPress() {
            onNext();
        }
    }));

    const onNext = () => {
        if (userExists) {
            if (isFNameValid && isLNameValid && isPhoneValid && isEmailValid) {
                onPassValue({
                    first_name: fName,
                    last_name: lName,
                    email,
                    is_free: isFree,
                    type: 1,
                    course_id,
                    whatsapp_country_code: countryCode,
                    whatsapp_phone_number: phone,
                    user_exists: true // Fixed typo
                });
            } else {
                if (!isFNameValid) setFNameError(true);
                if (!isLNameValid) setLNameError(true);
                if (!isPhoneValid) setPhoneError(true);
            }
        } else {
            if (isEmailValid && isPasswordValid && isConfirmPasswordValid && isFNameValid && isLNameValid && isPhoneValid) {
                onPassValue({
                    first_name: fName,
                    last_name: lName,
                    email,
                    password,
                    confirm_password: confirmPassword,
                    is_free: isFree,
                    type: 1,
                    course_id,
                    whatsapp_country_code: countryCode,
                    whatsapp_phone_number: phone
                });
            } else {
                if (!isEmailValid) setEmailError(true);
                if (!isPasswordValid) setPasswordError(true);
                if (!isConfirmPasswordValid) setConfirmPasswordError(true);
                if (!isPhoneValid) setPhoneError(true);
                if (!isFNameValid) setFNameError(true);
                if (!isLNameValid) setLNameError(true);
            }
        }
    };

    return (
        <>
            <p className="signup-title">Please <span style={{ color: 'rgb(152, 52, 240)' }}>Signup</span></p>
            {userExists ? (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                        User exists. You can continue with this email or change to a different email.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, justifyContent: 'space-between' }}>
                        <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                            <TextInputComponent
                                label="First Name"
                                placeholder="Enter your first name"
                                value={fName}
                                onchange={onChangeFirstName}
                                type="text"
                                error="Please add your first name"
                                isError={fNameError}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextInputComponent
                                label="Last Name"
                                placeholder="Enter your last name"
                                value={lName}
                                onchange={onChangeLastName}
                                error="Please add your last name"
                                isError={lNameError}
                            />
                        </Box>
                    </Box>
                    <PhoneNumberComponent
                        label="WhatsApp/Phone Number"
                        name="phoneNumber"
                        value={phone}
                        placeholder="WhatsApp/Phone Number"
                        onChange={onPhoneChange}
                        defaultCountry="CA"
                        readOnly={false}
                        isError={phoneError}
                        error="Please add valid WhatsApp/phone number"
                    />
                    <TextInputComponent
                        label="Email"
                        placeholder="Enter your email address"
                        value={email}
                        onchange={onEmailChange}
                        error={emailErrorMessage}
                        isError={emailError}
                    />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, justifyContent: 'space-between' }}>
                        <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                            <TextInputComponent
                                label="First Name"
                                placeholder="Enter your first name"
                                value={fName}
                                onchange={onChangeFirstName}
                                type="text"
                                error="Please add your first name"
                                isError={fNameError}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextInputComponent
                                label="Last Name"
                                placeholder="Enter your last name"
                                value={lName}
                                onchange={onChangeLastName}
                                error="Please add your last name"
                                isError={lNameError}
                            />
                        </Box>
                    </Box>
                    <PhoneNumberComponent
                        label="WhatsApp/Phone Number"
                        name="phoneNumber"
                        value={phone}
                        placeholder="WhatsApp/Phone Number"
                        onChange={onPhoneChange}
                        defaultCountry="CA"
                        readOnly={false}
                        isError={phoneError}
                        error="Please add valid WhatsApp/phone number"
                    />
                    <TextInputComponent
                        label="Email"
                        placeholder="Enter your email address"
                        value={email}
                        onchange={onEmailChange}
                        error={emailErrorMessage}
                        isError={emailError}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, justifyContent: 'space-between', marginTop: '22px' }}>
                        <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                            <TextInputComponent
                                label="New Password"
                                placeholder="Create a password"
                                value={password}
                                onchange={onPasswordChange}
                                type="password"
                                error="Please add valid password"
                                isError={errorPassword}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextInputComponent
                                label="Confirm Password"
                                placeholder="Re-enter your password"
                                value={confirmPassword}
                                type="password"
                                onchange={onConfirmPassword}
                                error="Password doesn’t match!"
                                isError={ConfirmPasswordError}
                            />
                        </Box>
                    </Box>
                    <p className="signup-price-subtext">
                        Note: The password must be a minimum of 8 characters with at least 1 upper case, 1 lower case, 1 number, and a special character.
                    </p>
                </>
            )}
        </>
    );
});

export default SignUpFormOne;