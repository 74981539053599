import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import TextInputComponent from "../../atom/Inputs/TextInput";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { forwardRef, useImperativeHandle } from "react";

const SignUpOtpForm = forwardRef(({ onVerifyOTP, email, onResendOTP }, ref) => {
    const [otp, setOTP] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onPress = async () => {
        if (!otp || otp.length < 4) {
            setError("Please enter a valid OTP");
            return;
        }
        setIsLoading(true);
        setError("");
        await onVerifyOTP(otp);
        setIsLoading(false);
    };

    useImperativeHandle(ref, () => ({
        onPress
    }));

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Verify Your Email
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                We've sent an OTP to {email}. Please enter it below:
            </Typography>
            
            <TextInputComponent
                placeholder="Enter OTP"
                value={otp}
                onchange={(e) => setOTP(e.target.value)}
                error={error}
                helperText={error}
                type="number"
                maxLength={6}
            />
{/* 
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextButtonComponet
                    text="Resend OTP"
                    classStyle="btn btn-secondary"
                    onButtonClick={onResendOTP}
                    width={120}
                />
                <TextButtonComponet
                    text="Verify"
                    isDisabled={isLoading}
                    onButtonClick={onPress}
                    width={120}
                />
            </Box> */}
        </Box>
    );
});

export default SignUpOtpForm;