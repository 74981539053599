import { Box, Typography, DialogActions, DialogContent, Alert, Stack, Grid } from "@mui/material";
import TextInputComponent from "../../atom/Inputs/TextInput";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { MINIMUM_PASSWORD_CHARACTERS, USER_ROLE, EMAIL_REGEX, getText, getDeviceId, PASSWORD_REGEX, onGetCurrencySymble } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const SignUpFormTwo = forwardRef(({ item = {}, course_id, onClose = () => {}, isRegistered = 0, courseType, onUpdateValue = () => {}, symbol = '' }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [cvv, setCvv] = useState('');
    const [times, setTime] = useState(0);
    const [errMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const [promCode, setPromoCode] = useState('');
    const [isPromoError, setIsPromoError] = useState(false);
    const [promoErrorMessage, setPromoErrorMessage] = useState('');
    const [discount, setDiscountPrice] = useState('');
    const [statusIndex, setStatusIndex] = useState(0);

    const selectedCurrency = localStorage.getItem('GlobalCurrency');
    const signUpresponse = useSelector(state => state.auth.get("signUpresponse"));
    const promoCodeDiscount = useSelector(state => state.auth.get('promoCodeDiscount'));
    const userVerifyData = useSelector(state => state.auth.get('verifyOtpData'));

    console.log(userVerifyData, 'userVerifyData')

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [paymentSubmitted, setPaymentSubmitted] = useState(false); // Track payment submission

    useEffect(() => {
        if (promoCodeDiscount?.status_code == 0) {
            setIsPromoError(true);
            setPromoErrorMessage(promoCodeDiscount?.message);
            onUpdateValue(courseType, 0);
        } else if (promoCodeDiscount?.discount_price) {
            setIsPromoError(false);
            setPromoErrorMessage('');
            setDiscountPrice(promoCodeDiscount?.discount_price);
            onUpdateValue(promoCodeDiscount?.discount_price, 1);
        }
    }, [promoCodeDiscount, courseType]);

    useEffect(() => {
        if (promCode && promCode?.length > 5) {
            onPromoVerify(promCode);
        }
    }, [promCode, courseType]);

    // Only act on signUpresponse after payment is submitted
    useEffect(() => {
        if (paymentSubmitted && signUpresponse?.status_code !== undefined) {
            if (!signUpresponse?.status && signUpresponse?.status_code == 0) {
                setErrorMessage(signUpresponse?.message);
            } else if (signUpresponse?.status) {
                setErrorMessage('');
                setMessage(signUpresponse?.message);
                _.delay(() => {
                    onClose();
                }, 1000);
            }
            setStatusIndex(signUpresponse?.status_code);
            setPaymentSubmitted(false); // Reset after processing
        }
    }, [signUpresponse, paymentSubmitted]);

    const onPromoChange = (e) => {
        const text = getText(e);
        setPromoCode(text);
    };

    const onPromoVerify = _.debounce((promo) => {
        dispatch(Actions.auth.getPromoCodeDiscount({ promo_code: promo, course_id, currency_id: selectedCurrency, course_type: courseType }));
    }, 2500);

    useImperativeHandle(ref, () => ({
        onPress(price, courseType) {
            onSignUp(price, courseType);
        }
    }));

    const onSignUp = async (price, courseType) => {
        const device_id = await getDeviceId();
        const data = {
            device_id,
            course_id,
            is_free: 0,
            type: courseType,
            ...item,
            promo_code: promCode,
            currency_id: selectedCurrency
        };
        handleSubmit(data, isRegistered);
    };

    const handleSubmit = async (data, isRegistered) => {
        if (!stripe || !elements) {
            setError("Stripe is not loaded yet.");
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            setPaymentSubmitted(true); // Mark payment as submitted
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: data?.first_name + ' ' + data?.last_name,
                    email: data?.email
                },
            });

            if (result.error) {
                setError(result.error?.message);
                setPaymentSubmitted(false); // Reset on error
            } else {
                const payment_method_id = _.get(result, 'paymentMethod.id');
                if (isRegistered) {
                    dispatch(Actions.course.registerNewCourseByUser({ ...data, payment_method_id }));
                } else {
                    dispatch(Actions.auth.registerPayment({ ...data, payment_method_id,user_id : userVerifyData?.data.user_id }));
                }
            }
        } catch (error) {
            setError(error.message);
            setPaymentSubmitted(false); // Reset on error
        }
    };

    return (
        <>
            {statusIndex == 1 && message ? (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="success">{message}</Alert>
                </Stack>
            ) : null}
            <p className="signup-title">Enter your card details to purchase</p>
            <Grid alignItems={'center'} container>
                <Grid item>
                    <TextInputComponent
                        label={"Promo Code"}
                        placeholder="Enter promo code"
                        value={promCode}
                        onchange={onPromoChange}
                        type="text"
                    />
                </Grid>
                {promCode?.length > 5 ? (
                    <Grid xs={6} item>
                        <p id="input-text" style={{ color: 'black', fontWeight: 800 }} className="input-text-title">
                            {isPromoError ? '' : `New purchase price :`}
                            <span style={{ color: '#8080f1', fontWeight: 800 }} className="input-text-title">
                                {' '}{isPromoError ? promoErrorMessage : symbol + discount}
                            </span>
                        </p>
                    </Grid>
                ) : (
                    <p id="input-text" className="input-text">
                        <span style={{ color: '#8080f1', fontWeight: 800 }} className="input-text-title">
                            {' '}Promo code should have minimum 5 characters
                        </span>
                    </p>
                )}
            </Grid>

            <form>
                <CardElement
                    id="my-card"
                    options={{
                        iconStyle: 'solid',
                        style: {
                            base: {
                                iconColor: '#c4f0ff',
                                color: '#000000',
                                fontSize: '16px',
                            },
                            invalid: {
                                iconColor: '#FFC7EE',
                                color: '#FFC7EE',
                            },
                        },
                    }}
                />
            </form>

            {error && <div style={{ fontSize: 18, fontFamily: 'Montserrat', color: 'red' }}>{error}</div>}
            {errMessage && <div style={{ fontSize: 18, fontFamily: 'Montserrat', color: 'red' }}>{errMessage}</div>}
        </>
    );
});

export default SignUpFormTwo;