import React, { useState, useEffect, useRef } from "react";
import { Box, DialogActions, DialogContent, Grid } from "@mui/material";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { MINIMUM_PASSWORD_CHARACTERS, EMAIL_REGEX, getText, getDeviceId, PASSWORD_REGEX, onGetCurrencySymble, COURSE_TYPE_LIST, setText } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import SignUpFormOne from "./SignUpFormOne";
import SignUpFormTwo from "./SignUpFormTwo";
import SignUpOtpForm from "./SignUpOtpForm";
import SecondStep from "../../../assets/Images/activeSlider.png";
import SecondOne from "../../../assets/Images/Signupgroup.png";
import DropDownComponent from "../../atom/Inputs/DropDown";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from "../../../core/repository/Repository";

const stripePromise = loadStripe(stripeKey);

const SignUpForm = ({ onClose = () => {}, paymentsheet = true, item = {}, stepIndex = 0, isRegistered = false, currencyId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formOneData, setFormOneData] = useState({});
    const [step, setStep] = useState(0);
    const [course, setCourse] = useState({});
    const [isFree, setIsFree] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [courseType, setCourseType] = useState(COURSE_TYPE_LIST[0]?.id);
    const [courseTypeList] = useState(COURSE_TYPE_LIST);
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(1);
    const [symbol, setSymbol] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [isPromo, setIsPromo] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isShow, setIsShow] = useState(0);
    const [deviceid, setDeviceId] = useState('');

    const formOneRef = useRef();
    const formTwoRef = useRef();
    const formOTPRef = useRef();

    const loadingAction = useSelector(state => state.common.get('loadingAction'));
    const show = useSelector(state => state.common.get('show'));
    const signupData = useSelector(state => state.auth.get('signUpresponse'));
    const verifyOtpData = useSelector(state => state.auth.get('verifyOtpData'));
    const verifyexistOtpData = useSelector(state => state.auth.get('verifyexistOtpData'));
    const currency = localStorage.getItem('GlobalCurrency');

    useEffect(() => {
        setIsShow(show == 1);
    }, [show]);

    useEffect(() => {
        const { action, loading } = loadingAction || {};
        if (action?.type === "SIGN_UP" || action?.type === 'ENROLL_NEW_COURSE_BY_REGISTERED_USER') {
            setLoading(loading);
        }
    }, [loadingAction]);

    useEffect(() => {
        setStep(stepIndex);
    }, [stepIndex, isRegistered]);

    useEffect(() => {
        setCourse(item);
        setIsFree(item?.is_free || 0);
        setType(item?.course_type || 1);

        if (item.course_currencies) {
            const selectedCurrency = item.course_currencies.find(c => c?.currency_id == currency) || item.course_currencies[0];
            const currencySymbol = onGetCurrencySymble(_.get(selectedCurrency, "currency.currency"));
            const price = _.get(selectedCurrency, "price", "0.00");
            setPrice(price);
            setSymbol(currencySymbol);
            setSelectedCurrency(selectedCurrency);
        }
    }, [currency, item]);

    useEffect(() => {
        if (signupData?.status_code === 1) {
            setStep(1); // Move to OTP verification
        }
    }, [signupData]);

    useEffect(() => {
        if (verifyOtpData?.status_code === 1) {
            setOtpVerified(true);
            if (!isFree && isShow) {
                setStep(2); // Move to payment form for paid courses
            } else if (isFree) {
                const data = {
                    device_id: deviceid,
                    user_id: verifyOtpData?.data.user_id,
                    course_id: _.get(course, 'id', ''),
                    is_free: 1,
                    promo_code: '',
                    ...formOneData
                };
                dispatch(Actions.auth.registerPayment(data));
                onCloseForm();
            }
        }
    }, [verifyOtpData, isFree, isShow, formOneData, dispatch]);

    const onNext = () => {
        formOneRef.current.onPress();
    };

    const onCloseForm = () => {
        setStep(0);
        onClose();
    };

    const onVerifyOTP = async (otp) => {
        try {
            setIsVerifying(true);
            await dispatch(Actions.auth.otpVerifyEmail({
                email: formOneData.email,
                otp: otp,
            }));
        } catch (error) {
            console.error("OTP verification failed:", error);
            throw error;
        } finally {
            setIsVerifying(false);
        }
    };

    const onSignUp = () => {
        formTwoRef.current.onPress(price, courseType);
    };

    const onCourseTypeChange = (e) => {
        const value = getText(e);
        setCourseType(value);
        const data = _.find(_.get(course, 'course_currencies', []), i => i?.currency_id == currency);
        setPrice(value == 1 ? _.get(data, 'price', '0.00') : _.get(data, 'other_price', '0.00'));
    };

    const onUpdateValue = (value, type) => {
        if (type === 0) {
            onCourseTypeChange(setText(value));
            setIsPromo(false);
        } else if (type === 1) {
            setIsPromo(true);
        }
    };

    const onSignUpFirst = async (data) => {
        const device_id = await getDeviceId();
        setDeviceId(device_id);
        setFormOneData(data);
        dispatch(Actions.auth.signUp({ ...data, device_id, currency_id: currency }));
    };

    const onFreeCourse = async (data) => {
        const device_id = await getDeviceId();
        setDeviceId(device_id);
        setFormOneData(data);
        dispatch(Actions.auth.signUp({ ...data, device_id, currency_id: currency }));
    };

    const onUserExist = async (data) => {
        const device_id = await getDeviceId();
        setDeviceId(device_id);
        setFormOneData(data);
        dispatch(Actions.auth.userExistOtp({ ...data, device_id, currency_id: currency }));
        setStep(1); // Move to OTP step immediately
    };

    return (
        <form>
            <DialogContent>
                <Box>
                    <Grid className='signup-price-container' container>
                        <Grid xs={6} item>
                            <Box>
                                <div className="signup-price-subtext">Selected course</div>
                                <div className="signup-price-text">{_.get(course, 'name', '')}</div>
                            </Box>
                        </Grid>
                        {isShow && (
                            <Grid xs={6} item>
                                {type === 2 && (
                                    <DropDownComponent
                                        placeholder="Please add the quiz"
                                        onchange={onCourseTypeChange}
                                        list={courseTypeList}
                                        selectedValue={courseType}
                                        outerStyle={{ padding: 10, paddingRight: 0, paddingLeft: 0 }}
                                        dropdownLabel="Select Course type"
                                    />
                                )}
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: type === 2 ? 'flex-start' : 'flex-end' }}>
                                    <p style={{ color: '#4a6375' }} className="signup-price-title">Price</p>
                                    <p style={{ color: '#8080f1', fontWeight: 800, textDecoration: isPromo ? "line-through" : 'none' }} className="signup-price-title">
                                        <span style={{ whiteSpace: 'pre' }}>  </span>{symbol + price}
                                    </p>
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <Box>
                        {isShow && (
                            <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                                <Box
                                    component="img"
                                    sx={{ height: 0.6, width: 0.6, alignSelf: 'center', display: 'flex' }}
                                    alt="Step indicator"
                                    src={step === 0 ? SecondOne : SecondStep}
                                />
                            </Box>
                        )}
                        {step === 0 && !isRegistered ? (
                            <SignUpFormOne
                                courseType={courseType}
                                isFree={isFree || !isShow}
                                selectedCurrency={selectedCurrency}
                                course_id={_.get(course, 'id', '')}
                                onPassValue={(data) => {
                                    if (data.user_exists) {
                                        onUserExist(data);
                                    } else {
                                        if (isFree || !isShow) {
                                            onFreeCourse(data);
                                        } else {
                                            onSignUpFirst(data);
                                        }
                                    }
                                }}
                                ref={formOneRef}
                            />
                        ) : step === 1 && !isRegistered ? (
                            <SignUpOtpForm
                                email={formOneData.email}
                                onVerifyOTP={onVerifyOTP}
                                ref={formOTPRef}
                            />
                        ) : step === 2 || (isRegistered && !isFree && isShow) ? (
                            <Elements stripe={stripePromise}>
                                <SignUpFormTwo
                                    onUpdateValue={onUpdateValue}
                                    item={formOneData}
                                    course_id={_.get(course, 'id', '')}
                                    onClose={onCloseForm}
                                    isRegistered={isRegistered}
                                    courseType={courseType}
                                    symbol={symbol}
                                    ref={formTwoRef}
                                />
                            </Elements>
                        ) : null}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 4 }}>
                {step === 0 && !isRegistered ? (
                    <Box sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                        <TextButtonComponet text="Cancel" classStyle="btn btn-secondary" width={120} onButtonClick={onCloseForm} />
                        <TextButtonComponet text={isFree || !isShow ? "Sign up" : "Next"} onButtonClick={onNext} width={120} />
                    </Box>
                ) : step === 1 && !isRegistered ? (
                    <Box sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                        <TextButtonComponet text="Cancel" classStyle="btn btn-secondary" width={120} onButtonClick={onCloseForm} />
                        <TextButtonComponet
                            text="Verify"
                            isDisabled={isVerifying}
                            onButtonClick={() => formOTPRef.current.onPress()}
                            width={120}
                        />
                    </Box>
                ) : step === 2 || (isRegistered && !isFree && isShow) ? (
                    <Box sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                        <TextButtonComponet text="Cancel" classStyle="btn btn-secondary" width={120} onButtonClick={onCloseForm} />
                        <TextButtonComponet isDisabled={isLoading} text="Proceed" width={120} onButtonClick={onSignUp} />
                    </Box>
                ) : null}
            </DialogActions>
        </form>
    );
};

export default SignUpForm;